import {useState, useEffect, useContext, useRef} from "react"
import { Switch, Route } from 'react-router-dom';
import WOW from "wowjs"
import "wowjs/css/libs/animate.css"
import Footer from "./components/Footer"
import Dividend from "./pages/Dividend"
import Navigation from "./components/Navigation"
import Spinner from "./components/Spinner"
import {ContractContext} from "./contexts/contractContext";



const App = () => {


  const {balance, isRequestProcessing} = useContext(ContractContext)

  const [navShown, setNavShown] = useState(false)

  const navContainer = useRef(null)


  const toggleNav = () => {

    if(navShown) {
      navContainer.current.classList.replace('left-0', '-left-full')
      setNavShown(false)
    } else {
      navContainer.current.classList.replace('-left-full', 'left-0')
      setNavShown(true)
    }
  }

  useEffect(() => {
    new WOW.WOW().init();
  }, [])

  
  
  return (
    <div className="App">
      <Navigation ref = {navContainer} hideNav = {toggleNav} />
      <Switch>
        <Route exact path="/">
          <Dividend showNav = {toggleNav} />
        </Route>
      </Switch>
      <Footer bscgTokenBalance = {balance} />
      {isRequestProcessing && <Spinner />}
      
      
    </div>
  );
}

export default App;