
const Footer = ({bscgTokenBalance}) => { 

    return (

        <footer className = "bg-light-dark p-10 w-full text-gray-400">
            <div className = "flex flex-col md:flex-row md:justify-between w-3/5 md:mx-auto">
                <div className = "mb-10">
                    <h2 className = "footer-section-header">Our Games</h2>
                    <ul className = "">
                        <li><a href = "https://casino.bscgames.win/games/baccarat" className = "hover:text-gray-300">Baccarat</a></li>
                        <li><a href = "https://casino.bscgames.win/games/european-roulette" className = "hover:text-gray-300">Roulette</a></li>
                    </ul>
                </div>

                <div className = "mb-10">
                    <h2 className = "footer-section-header">Blockchain &amp; Friends</h2>
                    <ul className = "">
                        <li className = "hover:text-gray-300">Your Balance: {bscgTokenBalance} BSCG</li>
                        <li className = "hover:text-gray-300">Network: BSC Mainnet</li>
                        <li className = "hover:text-gray-300">Smart Contract</li> 
                    </ul>
                </div>

                <div className = "mb-10">
                    <h2 className = "footer-section-header">Featured on</h2>
                    <ul className = "">
                        <li><a href = "https://github.com/BSC-GAMES/smart-contracts/tree/master" target = "_blank" rel="noreferrer" className = "hover:text-gray-300">Github</a></li>
                        <li><a href = "/" className = "hover:text-gray-300">State-of-the-DApps</a></li>
                        <li><a href = "/" className = "hover:text-gray-300">DappRadar</a></li> 
                    </ul>
                </div>

                <div className = "mb-10">
                    <h2 className = "footer-section-header">Reach out to us</h2>
                    <ul className = "">
                        <li><a href = "https://t.me/bscgameswin" target = "_blank" rel="noreferrer" className = "hover:text-gray-300">Telegram</a></li>
                        <li><a href = "https://twitter.com/bscgameswin" target = "_blank" rel="noreferrer" className = "hover:text-gray-300">Twitter</a></li>
                        <li><a href = "mailto:info@bscgames.win" target = "_blank" rel="noreferrer" className = "hover:text-gray-300">Email</a></li> 
                    </ul>
                </div>

            </div>
            <div className = "">
                <p className = "text-center text-gray-300">2021 BSC Games, All Rights Reserved</p>
            </div>
        </footer>
    );
}

export default Footer;