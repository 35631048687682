import {useState, createContext, useEffect, useCallback} from "react";
import Web3 from 'web3';
import {BSCGTokenContractABI} from '../helpers/ABI/abisFile'


let web3, BSCGTokenContractInstance;
const BSCGTokenContractAddress = "0xaf3eeA517E5d3eBA972d1f5a0d44f642a9ceF4De";

const convertPeriod = (seconds) => {

    let date = new Date(0); // The 0 there is the key, which sets the date to the epoch
    date.setUTCSeconds(seconds);

    const dateArray = date.toString().split(' ');

    return `${dateArray[2]} ${dateArray[1]} ${dateArray[4].substr(0, 5)}`;

}


export const ContractContext = createContext(null)



export const ContractContextProvider = ({children}) => {





    // user account details
    const [address, setAddress] = useState(null);
    const [balance, setBalance] = useState(0);

    // running pool data
    const [period, setPeriod] = useState("");
    const [dividend, setDividend] = useState(0);
    const [frozenToken, setFrozenToken] = useState(0);

    // my token data
    const [frozen, setFrozen] = useState(0);

    // last  days data
    const [dividendHistory, setDividendHistory] = useState([]);

    // user total frozen before the current period
    const [totalUserFrozenAsAtLastPeriod, setTotaluserFrozenAsAtLastPeriod] = useState(0)

    // network id of the selected network
    const [network, setNetwork] = useState(null)

    // a state to control the loader component
    const [isRequestProcessing, setIsRequestProcessing] = useState(true);

    const getChainId = async () => {
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        return Number(chainId)
    }
    
    const getConnectedAccount = async () => {
        
        let [address] = await web3.eth.getAccounts()
        return address;
    }

    const fetchUserData = async (address) => {  
        if(!address) return;
    
        const userData = {
            balance: 0,
            frozen: 0,
            frozenForLastPeriod: 0
        };
    
        try {
            const userBalance = await BSCGTokenContractInstance.methods.balanceOf(address).call();
            const userFrozenToken = await BSCGTokenContractInstance.methods.frozen(address).call();
            const currentPeriod = await getPeriod(0, true);
    
            userData.balance = web3.utils.fromWei(userBalance.toString(), 'ether')
    
            // this if else ensures we get the frozen for last periosd even if that was the first and last time they froze
            if(userFrozenToken.period < currentPeriod) {
                userData.frozenForLastPeriod = Number(web3.utils.fromWei(userFrozenToken.newlyFrozen, "ether")) + Number(web3.utils.fromWei(userFrozenToken.cummulative, "ether"));
            } else {
                userData.frozenForLastPeriod = web3.utils.fromWei(userFrozenToken.cummulative, "ether")
            }
    
            // this gets the total token frozen by a user for all the periods
            userData.frozen = Number(web3.utils.fromWei(userFrozenToken.newlyFrozen.toString(), 'ether')) + Number(web3.utils.fromWei(userFrozenToken.cummulative.toString(), 'ether'))
    
        } catch(err) {

            console.log(err)

        } finally {

            return userData;

        }
    }

    const connectWallet = async () => {
        
        if(!window.ethereum || !window.web3) return;

        let [address] = await window.ethereum.request({ method: 'eth_requestAccounts' });
        
        if(!address) return;

        const userData = await fetchUserData(address)

        setAddress(address);
        setBalance(userData.balance);
        setFrozen(userData.frozen)
        setTotaluserFrozenAsAtLastPeriod(userData.frozenForLastPeriod);
    }


    const init = useCallback(async () => {


        try {

            let chainId;

            if(window.web3 || window.ethereum) {
                chainId = await getChainId()
                setNetwork(chainId)
            }
                
            



            if((window.web3 || window.ethereum) && chainId === 56 ) {


                // the browser is ethereum enebled and on bsc network: 56
                // create the contract instance here with the window ethereum provider
                
                web3 = new Web3(Web3.givenProvider);

                const address = await getConnectedAccount();


                // BSCG TOKEN CONTRACT INSTANCE HERE
                BSCGTokenContractInstance = new web3.eth.Contract(BSCGTokenContractABI, BSCGTokenContractAddress);

                // listen for events here
                // when user freeze their token
                BSCGTokenContractInstance.events.Froze().on("data", async (data) => {

                    setFrozenToken(web3.utils.fromWei(data.returnValues.totalFrozen.toString(), 'ether')) // running pool

                })

                // when user unfreeze their token
                BSCGTokenContractInstance.events.UnFroze().on("data", async (data) => {

                    setFrozenToken(web3.utils.fromWei(data.returnValues.totalFrozen.toString(), 'ether')) //running pool

                })

                // when dividend is added
                BSCGTokenContractInstance.events.DividendValue().on("data", async (data) => {
        
                    const newDividend = data.returnValues.amount;
                    const formatedPeriod = convertPeriod(data.returnValues.period);

                    setDividend(web3.utils.fromWei(newDividend.toString(), 'ether'))
                    setPeriod(formatedPeriod)

                })

                
        
                if(address) { // if user is previously connected

                    const userData = await fetchUserData(address);

                    setAddress(address);
                    setBalance(userData.balance);
                    setFrozen(userData.frozen)
                    setTotaluserFrozenAsAtLastPeriod(userData.frozenForLastPeriod);
                }
                
            } else { // if the browser is not ethereum enabled or is not on the right network

                // CONNECT TO A REMOTE NODE
                web3 = new Web3('https://bsc-dataseed1.ninicoin.io/');

                // BSCG TOKEN CONTRACT INSTANCE HERE
                BSCGTokenContractInstance = new web3.eth.Contract(BSCGTokenContractABI, BSCGTokenContractAddress);

                // listen for events here
                // when user freeze their token
                BSCGTokenContractInstance.events.Froze().on("data", async (data) => {

                    setFrozenToken(web3.utils.fromWei(data.returnValues.totalFrozen.toString(), 'ether')) // running pool
                    setFrozen(web3.utils.fromWei(data.returnValues.totalLocked.toString(), 'ether')) //user frozen

                })

                // when user unfreeze their token
                BSCGTokenContractInstance.events.UnFroze().on("data", async (data) => {

                    setFrozenToken(web3.utils.fromWei(data.returnValues.totalFrozen.toString(), 'ether')) //running pool

                })

                // when dividend is added
                BSCGTokenContractInstance.events.DividendValue().on("data", async (data) => {
        
                    const newDividend = data.returnValues.amount;
                    const formatedPeriod = convertPeriod(data.returnValues.period);

                    setDividend(web3.utils.fromWei(newDividend.toString(), 'ether'))
                    setPeriod(formatedPeriod)

                })
            }


            const totalFrozenToken = await BSCGTokenContractInstance.methods.totalFrozen().call();
        
            // replace with this if error occur
            // const pastDividends = await BSCGTokenContractInstance.getPastEvents("DividendValue", {fromBlock: '0', toBlock: 'latest'})
            const pastDividends = await BSCGTokenContractInstance.getPastEvents("DividendValue")


            let lastPeriod, formatedPeriod, lastDividend;

            if(pastDividends.length) {
                lastDividend = pastDividends[pastDividends.length - 1].returnValues.amount;
                lastPeriod = pastDividends[pastDividends.length - 1].returnValues.period;
                formatedPeriod = convertPeriod(lastPeriod);
            } else {
                lastDividend = 0;
                lastPeriod = await getPeriod(0, true);
                formatedPeriod = convertPeriod(lastPeriod);
            }

            // convert token value to ether
            const convertedDividend = web3.utils.fromWei(lastDividend.toString(), "ether");
            const convertedFrozenToken = web3.utils.fromWei(totalFrozenToken.toString(), 'ether');


            // data for the running pool is set here
            setFrozenToken(convertedFrozenToken)
            setPeriod(formatedPeriod)
            setDividend(convertedDividend)




            // get last 7 days data here
            Promise.all([getPeriod(1, true), getPeriod(2, true), getPeriod(3, true), getPeriod(4, true), getPeriod(5, true), getPeriod(6, true), getPeriod(7, true)])
            .then( async periodRes => {
                const dividendData = await Promise.all([getpreviousDividend(periodRes[0]), getpreviousDividend(periodRes[1]), getpreviousDividend(periodRes[2]), getpreviousDividend(periodRes[3]), getpreviousDividend(periodRes[4]), getpreviousDividend(periodRes[5]), getpreviousDividend(periodRes[6])])
                return {periodRes, dividendData}
            }).then( async ({dividendData, periodRes}) => {
                const frozenData = await Promise.all([getPreviousFrozen(periodRes[0]), getPreviousFrozen(periodRes[1]), getPreviousFrozen(periodRes[2]), getPreviousFrozen(periodRes[3]), getPreviousFrozen(periodRes[4]), getPreviousFrozen(periodRes[5]), getPreviousFrozen(periodRes[6])])
                return {periodRes, dividendData, frozenData}
            }).then(({periodRes, dividendData, frozenData}) => {
                let historyArr = [];
                for(let i = 0; i < periodRes.length; i++) {
                    historyArr.push({
                        period: convertPeriod(periodRes[i]),
                        dividend: web3.utils.fromWei(dividendData[i], "ether"),
                        frozen: web3.utils.fromWei(frozenData[i], "ether")
                    })
                }

                setDividendHistory(historyArr);
                setIsRequestProcessing(false)
            })
            .catch(e => {
            })

        } catch(err) {
            console.log(err)
        }

    // eslint-disable-next-line
    },[])


    const freezeAvailableToken = async (e) => {

        e.preventDefault();
        
        if(!address) return;
    
        try {
            await BSCGTokenContractInstance.methods.freeze().send({from: address}, (err, transactionHash) => {
                if(!err && transactionHash)  setIsRequestProcessing(true);
            })
        } catch(err) {
            console.log(err)
        } finally {

             // GET USER UPDATED DATA
             try {
                // USER'S UPDATED BALANCE AFTER SUCCESFULL FREEZING
                const userBalance = await BSCGTokenContractInstance.methods.balanceOf(address).call();

                // user frozen token balance
                const userFrozenToken = await BSCGTokenContractInstance.methods.frozen(address).call();
                const totalFrozenForUser = Number(web3.utils.fromWei(userFrozenToken.newlyFrozen.toString(), 'ether')) + Number(web3.utils.fromWei(userFrozenToken.cummulative.toString(), 'ether'))
    
                // set new states here
                setFrozen(totalFrozenForUser)
                setBalance(web3.utils.fromWei(userBalance.toString(), 'ether'))
            } catch(err) {
                console.log(err);
            } finally {
                setIsRequestProcessing(false);
            }
        }
         
        
    }

    const unfreezeToken = async (e) => {
    
        e.preventDefault();

        if(!address) return

        try {
            await BSCGTokenContractInstance.methods.unfreeze().send({from: address}, (err, transactionHash) => {

                if(!err && transactionHash)  setIsRequestProcessing(true);
            })
        } catch(err) {
            console.log(err)
        } finally {
            try{
                // GET USER'S UPDATED BALANCE AFTER SUCCESFULL RELEASE
                const userBalance = await BSCGTokenContractInstance.methods.balanceOf(address).call();

                setBalance(web3.utils.fromWei(userBalance.toString(), 'ether'));
                setFrozen(0); // the unfreeze function unfreezes the entire frozen token of a user
            } catch(err) {
                console.log(err);
            } finally {
                setIsRequestProcessing(false);
            }
        }
        
    }

    const claimDividend = async (e) => {

        e.preventDefault();

        if(!address) return;

        try {
            await BSCGTokenContractInstance.methods.claimDividend(address).send({from: address}, (err, transactionHash) => {
                if(!err && transactionHash)  setIsRequestProcessing(true);
            })
        } catch(err) {
            console.log(err)
        } finally {
            setIsRequestProcessing(false);
        }
        
    }   

    const getPeriod = async (offset, forward) => {

        const period = await BSCGTokenContractInstance.methods.getPeriod(offset, forward).call()
        return period;
    }

    const getpreviousDividend = async (period) => {

        const dividend = await BSCGTokenContractInstance.methods.dividendValue(period).call();
        return dividend;
    }

    const getPreviousFrozen = async (period) => {
        const frozen = await BSCGTokenContractInstance.methods.frozenYesterday(period).call();
        return frozen;
    }


    useEffect(() => {

        init()

    }, [init])

            

    return(
        <ContractContext.Provider value = {{
            isRequestProcessing,
            period,
            dividend,
            frozenToken,
            balance,
            frozen,
            address,
            network,
            dividendHistory,
            totalUserFrozenAsAtLastPeriod,
            freezeAvailableToken,
            unfreezeToken,
            claimDividend,
            connectWallet
                }}
        >

            {children}
        </ContractContext.Provider>
    );
}