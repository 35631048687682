import LogoPNG from '../assets/images/logo.png';
import {GiHamburgerMenu} from 'react-icons/gi'
import {FaTelegramPlane} from "react-icons/fa"

const Header = ({walletAddress, balance, connectWallet, showNav}) => { 


    return (

        <header className = "h-16 bg-light-dark p-4 md:px-20 flex items-center sticky top-0">
            <div className = "hover:bg-gray-600 transition-all duration-500 cursor-pointer p-3 rounded-full mr-4 md:mr-10">
                <GiHamburgerMenu className = "text-white text-2xl" onClick = {showNav} />
            </div>

            <div className = "h-10">
                <img src = {LogoPNG} className = "h-full w-28 md:w-36 md:h-10" alt = "bsc games logo" />
            </div>

            <div className = "ml-auto flex items-center">
                {walletAddress ? 
                    <div className = "text-white flex flex-col md:flex-row text-xs sm:text-sm md:text-base">
                        <span className = "mr-2">{`${walletAddress.substring(0, 4)}...${walletAddress.substring(walletAddress.length - 3, walletAddress.length)}`}</span>
                        <span className = "">Bal: {balance} BSCG</span>
                    </div>
                    :
                    <button className = "bg-blue hover:bg-light-blue text-white px-3 py-2 rounded font-bold" onClick = {connectWallet}>connect</button>
                }
                
                
                <a href = "https://t.me/bscgameswin" target = "_blank" rel="noreferrer" className = "ml-5 md:ml-10"><FaTelegramPlane className = "text-white text-2xl" /></a>
            </div>
        </header>
    );
}

export default Header;