import {ImSpinner2} from "react-icons/im";

const Spinner = () => {
    return(
        <div className = "fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-filter backdrop-blur">
            <ImSpinner2 className = "text-5xl text-gray-400 animate-spin" />
        </div>
    );
}

export default Spinner;