import { forwardRef } from 'react';
import {AiFillHome} from 'react-icons/ai';
import {FaGamepad} from 'react-icons/fa'
import {CgFileDocument} from 'react-icons/cg'
import {RiHandCoinLine} from  'react-icons/ri'

const Navigation = forwardRef((props, ref) => {

    const handleNavigationHide = (e) => {
        if(e.target.id === "nav-container") props.hideNav()
    }
    return(

        <div ref = {ref} className = "h-full w-full fixed z-50 bg-dark bg-opacity-20 -left-full transition-all duration-500" id = "nav-container" onClick = {handleNavigationHide}>
            <div className = "h-full w-2/3 max-w-sidenav bg-lighter-dark text-white">
                <div className = "border-b p-3">
                    <h2 className = "font-bold text-xl">Navigation</h2>
                </div>
                <nav className = "mt-2">
                    <span className = "flex items-end p-3 pl-4 hover:bg-lightest-dark hover:bg-opacity-50 transition-all duration-500 cursor-pointer">
                        <AiFillHome className = "text-xl mr-6" />
                        <a href = "https://bscgames.win" className = "font-bold text-sm">Home</a>
                    </span>
                    <span className = "flex items-end p-3 pl-4 hover:bg-lightest-dark hover:bg-opacity-50 transition-all duration-500 cursor-pointer">
                        <FaGamepad className = "text-xl mr-6" />
                        <a href = "https://casino.bscgames.win" className = "font-bold text-sm">Games</a>
                    </span>
                    <span className = "flex items-end p-3 pl-4 bg-lightest-dark cursor-pointer">
                        <RiHandCoinLine className = "text-xl mr-6" />
                        <a href = "/" className = "font-bold text-sm">Dividend</a>
                    </span>
                    <span className = "flex flex-end p-3 pl-4 items-end hover:bg-lightest-dark hover:bg-opacity-50 transition-all duration-500 cursor-pointer">
                        <CgFileDocument className = "text-xl mr-6" />
                        <a href = "https://docs.bscgames.win/" className = "font-bold text-sm">White Papper</a>
                    </span>
                </nav>
            </div>
        </div>
    );
});

export default Navigation;