import {useContext, useEffect} from "react";
import Header from "../components/Header";
import WrongNetworkNoticeComp from "../components/WrongNetworkNoticeComp";
import {ContractContext} from "../contexts/contractContext";


const Dividend = ({showNav}) => {

    const {
        frozenToken,
        dividend,
        period,
        frozen,
        balance,
        address,
        network,
        freezeAvailableToken,
        totalUserFrozenAsAtLastPeriod,
        unfreezeToken,
        claimDividend,
        dividendHistory,
        connectWallet
    } = useContext(ContractContext)

    useEffect(()=> {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', () => {
              window.location.reload();
            });

            window.ethereum.on('accountsChanged', function (accounts) {
                window.location.reload();
              })
          }
    },[])
    return(
        <div className = "">
            <Header walletAddress = {address} balance = {balance} connectWallet = {connectWallet} showNav = {showNav}/>


            <div className = "bg-keyBoard bg-cover w-full h-auto">
                <div className = "bg-light-dark h-full w-full bg-opacity-70  text-gray-300 p-5 md:px-28 lg:px-56">

                    {network ? network !== 56 ? <WrongNetworkNoticeComp ethereumEnabled = {true}/> : null : <WrongNetworkNoticeComp ethereumEnabled = {false} />}

                    <div>
                        <h1 className = "mt-5 text-center text-2xl md:text-3xl font-roboto font-bold">THE DIVIDEND</h1>
                        <a href = "https://bscscan.com//address/0xaf3eeA517E5d3eBA972d1f5a0d44f642a9ceF4De" className = "text-base text-center block text-gray-400 hover:text-white font-regular" target = "_blank"  rel="noreferrer">contract address: <span>{"0xaf3...4De"}</span></a>
                        
                        <div className = "flex flex-col md:flex-row my-10 justify-around wow fadeInDown">
                            <div className = " mb-10 md:mb-0 p-5 font-regular rounded bg-light-dark rounded-r-none md:w-1/2">
                                <h2 className = "text-2xl mb-5 font-bold">RUNNING POOL</h2>
                                <div>
                                    <p className = "data-items"><span>Period</span><span>{period}</span></p>
                                    <p className = "data-items"><span>Dividend</span><span>{dividend} BNB</span></p>
                                    <p className = "data-items"><span>Frozen Token</span><span>{frozenToken} BSCG</span></p>
                                </div>
                            </div>
                            <div className = "md:border-l p-5 font-rgular rounded bg-light-dark md:rounded-l-none md:w-1/2">
                                <h2 className = "text-2xl mb-5 font-bold">MY TOKEN</h2>
                                <div>
                                    <div className = "data-items">
                                        <p className = "inline-block">Balance</p>
                                        <p><span className = "align-bottom md:text-sm">{balance} BSCG</span><button className = "ml-4 p-2 py-1 rounded bg-red" onClick = {freezeAvailableToken}>Freeze</button></p> 
                                    </div>
                                    <div className = "data-items">
                                        <p className = "inline-block">Frozen</p>
                                        <p><span className = "align-bottom md:text-sm">{frozen} BSCG</span><button className = "ml-4 px-2 py-1 rounded bg-blue" onClick = {unfreezeToken}>Release</button></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className = "mx-5 md:mx-28 lg:mx-56 p-5 text-white font-regular mt-20 bg-light-dark wow fadeInUp" data-wow-delay="1s">
                <h2 className = "text-2xl mb-20 font-bold">Last Period <small className = "test-base font-thin">{dividendHistory.length && dividendHistory[0].period}</small></h2>

                <div className = "flex flex-col lg:flex-row justify-between">
                    <div className = "mb-10 lg:mb-0">
                        <p>Dividend collected (BNB)</p>
                        <p>{dividendHistory.length && dividendHistory[0].dividend}</p>
                    </div>
                    <div className = "mb-10 lg:mb-0">
                        <p>Total frozen (BSCG)</p>
                        <p>{`${totalUserFrozenAsAtLastPeriod}/`}<span>{dividendHistory.length && dividendHistory[0].frozen}</span></p>
                    </div>
                    <div className = "flex justify-between items-end">
                        <div className = "">
                            <p>My dividend (BNB)</p>
                            <p>{Number(totalUserFrozenAsAtLastPeriod) && dividendHistory.length && Number(dividendHistory[0].dividend) && Number(dividendHistory[0].frozen) && `~ ${((Number(dividendHistory[0].dividend) * Number(totalUserFrozenAsAtLastPeriod))/Number(dividendHistory[0].frozen)).toFixed(3)}`}</p>
                        </div>
                        <button className = "ml-4 px-2 py-1 rounded bg-blue" onClick = {claimDividend}>Claim</button>
                    </div>
                </div>
                <p className = "text-center text-sm mt-10 bg-yellow-600">Dividend is added and should be claimed weekly</p>
            </div>
        
            <div className = "my-20 mx-5 md:mx-28 lg:mx-56 p-5 text-white font-regular bg-light-dark wow fadeInUp" data-wow-delay="1s">
                <h2 className = "text-2xl mb-20 font-bold">Last 7 Period</h2>

                <table className = "w-full table-auto">
                    <thead className = "bg-dark bg-opacity-50">
                        <tr className = "text-white">
                            <th className = "py-4 font-bold tracking-widest align-top">Period</th>
                            <th className = "py-4 font-bold tracking-widest">Frozen <small className = "font-thin">(BSCG)</small></th>
                            <th className = "py-4 font-bold tracking-widest">Dividend <small className = "font-thin">(BNB)</small></th>
                        </tr>
                    </thead>
                    <tbody className = "">
                        {dividendHistory.map((data, index) => {
                            return(
                                <tr className = {(index + 1) % 2 === 0 ? "bg-dark bg-opacity-30" : ""} key = {Math.random()}>
                                    <td className = "text-center p-2 text-gray-300">{data.period}</td>
                                    <td className = "text-center p-2 text-gray-300">{data.frozen}</td>
                                    <td className = "text-center p-2 text-gray-300">{data.dividend}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default Dividend;

