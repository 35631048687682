const WrongNetworkNoticeComp = ({ ethereumEnabled }) => {
    return (
        <div className="bg-light-dark text-xs p-4 text-center mx-auto text-red rounded">
            <p>
                {ethereumEnabled ? "Wrong network detected! please switch to Binance smart chain network" : "Please open this page in an ethereum enabled browser"}
            </p>
        </div>
    );
}

export default WrongNetworkNoticeComp;